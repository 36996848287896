<template>
  <div class="w-1920">
    <header-user></header-user>
    <store-search></store-search>
    <store-Info-chip></store-Info-chip>
    <store-image sid="1" src="" :occupy="true"></store-image>
    <store-menu ref="menu"></store-menu>
    <div style="height:30px;background:#f5f5f5"></div>
    <div class="store">
      <div class="store-block w-1200">
        <div class="store-block-right">
          <div class="content">
            <!-- <store-wares ref="wares"></store-wares> -->
            <el-table :data="data" style="width: 100%">
<!--              <el-table-column prop="spuNum" label="产品编号"></el-table-column>-->
              <el-table-column prop="name" label="产品名称">
                <template slot-scope="scope">
                  <el-link
                    :href="
                      `#/store/${scope.row.storeId}/details/${scope.row.spuId}`
                    "
                    target="_blank"
                  >
                    <span v-html="scope.row.name"></span>
                  </el-link>
                </template>
              </el-table-column>
              <el-table-column prop="priceSection" label="价格区间">
              </el-table-column>
              <el-table-column label="商品品牌" width="200px">
                <template slot-scope="scope">
                  <el-tag type="success">{{ scope.row.brandName }}</el-tag>
                </template>
              </el-table-column>
              <!-- <el-table-column prop="price" label="价格"> </el-table-column> -->
              <!-- <el-table-column prop="inventory" label="库存"> </el-table-column> -->
            </el-table>
          </div>
          <div class="fenye" v-if="data != ''">
            <el-pagination
              @current-change="pagechange"
              :current-page="page.current"
              style="text-align: right;margin-top: 20px"
              background
              :total="page.total"
              :page-size="page.size"
              layout="prev, pager, next, jumper, ->, total"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
    <!-- <baoyou></baoyou> -->
    <foot></foot>
    <cart></cart>
  </div>
</template>

<script>
import store from './mixins-components';

import classify, { sortOrder } from './index-classify';

import { sort } from '../../api/store';
import { get, post } from '@/utils/request';

export default {
  mixins: [store, classify],
  data() {
    return {
      sid: '',
      sortData: {
        index: -1,
        sort: '',
      },
      sortParams: {},
      sortList: [],
      data: [],
      page: {
        total: 1,
        size: 10,
        current: 1,
      },
      storeId: '',
      prodCatId: '',
    };
  },
  created() {
    this.storeId = this.$route.params.storeId;
    this.prodCatId = this.$route.params.selectMenu;
    // sort().then((data) => {
    //   this.sortList = data;
    // });
    // this.$nextTick(() => {});
    this.getlist(this.$route.params.storeId, this.$route.params.selectMenu);
  },
  methods: {
    getlist(storeId, prodCatId) {
      get(
        'api/storeCategory/getStoreProductByCategory?pageSize=10&pageNum=1&storeId=' +
          storeId +
          '&prodCatId=' +
          prodCatId +
          ''
      ).then((res) => {
        this.data = res.data.data.list;
        this.page.total = res.data.data.total;
      });
    },

    pagechange(p) {
      this.page.current = p;
      get(
        'api/storeCategory/getStoreProductByCategory?pageNum=' +
          p +
          '&pageSize=10&storeId=' +
          this.storeId +
          '&prodCatId=' +
          this.prodCatId +
          ''
      ).then((res) => {
        this.data = res.data.data.list;
      });
    },
    getParams() {
      return {
        ...this.sortParams,
        ...this.$refs.menu.getParams(),
      };
    },
    handleSortChange(data, index) {
      if (this.sortData.index != index) {
        this.sortData.sort = '';
      }
      this.sortData.index = index;
      this.sortData.sort = sortOrder(this.sortData.sort);
      if (!this.sortData.sort) {
        this.sortData.index = -1;
      }
      this.sortParams = {
        sort: this.sortData.sort,
        ...data,
      };
      this.$refs.wares.initGrid();
    },
  },
};
</script>

<style lang="scss" scoped>
@import './index-classify.scss';
</style>
