
import store_classify from './store-classify';
import store_wares from './store-wares';

export default {
  components:{
    "store-classify": store_classify,
    "store-wares": store_wares,
  }
}

export function sortOrder(item) {
  if (item == "asc") {
    item = "desc";
  } else if (item == "desc") {
    item = "";
  } else {
    item = "asc";
  }
  return item;
}